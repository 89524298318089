.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    font-weight: 300;
    font-size: 1.5rem;
}

.divider{
    width: 100%;
    margin: 0.25rem;
}

.detalle{
    width: 100%;
    display: flex;
    padding: 0.375rem;
}

.nombre{
    font-weight: bold;
    width: 40%
}
.info{
    width: 60%;
}