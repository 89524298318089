.buttons {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  margin: 0 0.25rem;
  display:flex;
  justify-content: center;
  align-items: center;
}

.buttons:hover {
  box-shadow: 0px 1px 3px 1px rgb(255, 254, 254);
}

.buttons:focus {
  outline: none;
}

.icons-container div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0.3rem 0rem 0.3rem;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tiptext {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  visibility: hidden;
  width: 6rem;
  height: 0.4rem;
  background-color: #707070;
  color: #ffffff;
  text-align: center;
  padding: 0.38rem 0;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.6rem;
  font-family: var(--font-g);
  font-weight: 300;
}

.tooltip .tiptext::after {
  content: "";
  position: absolute;
  border-width: 0.31rem;
  border-style: solid;
}

.tooltip:hover .tiptext {
  visibility: visible;
}

.tooltip.bottom.page{
    margin: 1em;
    margin-right: 0;
}

.tooltip.bottom .tiptext {
  margin-left: -3.75rem;
  top: 120%;
}

.tooltip.bottom .tiptext::after {
  margin-left: 1.5rem;
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent #707070 transparent;
}

.buttons img {
  position: relative;
  animation-duration: 0.2s;
  animation-name: slidein;
  height: 40px;
  width: 40px;
}

@keyframes slidein {
  from {
    height: 0px;
    width: 0;
  }

  to {
    height: 2.6rem;
    width: 2.6rem;
  }
}
