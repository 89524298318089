.buttonsContainer{
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.icons{
    display: flex;
    justify-content: space-between
}