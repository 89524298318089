main {
    height: calc(100vh - 150px);
    padding: 0 0.5em
}
  
.row-grid {
    height: 80%;
    margin-bottom: 1%;
    position: relative;
}
.row-grid2 {
    width: 150%;
    height: 80%;
    margin-bottom: 1%;
    position: relative;
}
.row-grid-bind{
    min-width: 112%;
    max-width: 1420px;
    height: 80%;
    margin-bottom: 1%;
    position: relative;
}

.row-grid-gire{
    min-width: 112%;
    max-width: 1420px;
    height: 80%;
    margin-bottom: 1%;
    position: relative;
}
.row-grid-transacciones{
    width: 180%;
    height: 80%;
    margin-bottom: 1%;
    position: relative;
}

#second-grid-container{
    height: 70%
}

#grid-detalle-acreditacion {
    height: 80%;
}
