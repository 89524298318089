.container {
    height: 80vh;
    width: 100%
}

.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 0;
    margin-bottom: 1rem;
}

.buttonContainer {
    display: flex; 
    justify-content: center;
    margin-top: 0.5rem; 
}

.buttonCerrar {
    color: inherit;
    width: 14rem;
    height: 2rem;
    background: #ddd!important;
    border-radius: 21px!important;;
    text-transform: capitalize!important;;
    font-weight: 300;
    font-family: 'Roboto, sans-serif'
}