.form__input-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__input-row .column{
    width: 45%
}

.form__input-row img {
    cursor: pointer;
    margin-left: 2rem;
}

.add-file-button{
    color: #63d1a2;
    border-radius: 5rem;
    border: 1px solid #63d1a2;
    height: 2rem;
    width: 10rem;
    background-color: transparent;
    cursor: pointer;
    margin-top: 2rem;
}