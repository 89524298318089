.divider{
    width: 100%;
    margin: 0.5rem 0;
}
.detalle{
    width: 100%;
    display: flex;
    padding: 0.25rem 0;
}

.nombre{
    font-weight: bold;
    width: 40%
}
.info{
    width: 60%;
    text-align: right;
}